import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Areas of Expertise",
        tab2 = "Hobbies & Volunteer Work",
        tab2B = "Soft Skills",
        tab3 = "Experience",
        tab4 = "Education & Certification";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        {/*<Tab>{tab1}</Tab>*/}
                                        {/*<Tab>{tab2}</Tab> Hobbies and volunteer work*/}
                                        {/*<Tab>{tab2B}</Tab>*/}
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    {/*<TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                            <li>
                                                <b> Software Development </b><br/>
                                                As a software developer, I possess a strong understanding of algorithms and software design patterns, which I honed during my undergraduate studies. My expertise in object-oriented environments allows me to abstract class behaviors, reducing code complexity and utilizing design patterns such as event systems, observer patterns, and singleton classes when appropriate. I am always eager to learn new tools and environments, staying up-to-date with the latest best practices and patterns.
                                                <br/><br/>
                                                My background includes experience with various programming languages and environments, including C, Java, Python, C#, HLSL, ReactJS. I am currently building skills in Unreal C++ and Houdini's VEX/node system. I have also developed tools in Unity to improve workflows on projects such as Inner Matter and Hexit. I am committed to continuously expanding my skill set.
                                                </li>
                                                <li>
                                                <b> Technical Art </b><br/>
                                                As a Technical Artist, I leverage my coding abilities to provide innovative technical solutions to artistic challenges. I have a passion for writing complex fragment and geometry shaders, which allow me to create stunning real-time effects with minimal performance impact. My knowledge of the 3D pipeline enables me to utilize tools such as Substance Painter and 2D art software to generate specialized maps that I can then integrate into custom shader code.
                                                <br/><br/>
                                                In my continuous effort to streamline my workflow, I explore how code can provide shortcuts. For instance, I recently acquired MEL scripting skills to enhance my rigging pipeline for a project, resulting in more natural bone twists and secondary motion for animation.
                                                </li>
                                                <li>
                                                <b> 3D Pipeline </b><br/>
                                                I have a broad range of experience in sculpting, modeling, texturing, rigging, and animating using software such as Blender, 3ds Max, Maya, Z Brush, and Houdini. I am continuously seeking to expand my skillset, which not only enhances my technical art capabilities but also allows me to support project efficiency by addressing pipeline bottlenecks when not focused on software or technical art tasks. I find creating to be a fun and enjoyable process and welcome the opportunity to explore new techniques and technologies.
                                                </li>
                                                
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                            <li>
                                                
                                                <b> Teamwork </b><br/>
                                                    I have a strong track record of working effectively in diverse and multi-talented teams. My experience collaborating on group projects has honed my communication skills, and my teaching experience has further strengthened my ability to effectively convey ideas and information to others.
                                                </li>
                                                <li>
                                                <b> Leadership </b><br/>
                                                I have developed strong leadership skills through my role as Lead Technical Teaching Assistant for Carnegie Mellon’s Building Virtual Worlds class. This experience has equipped me with the knowledge and ability to lead teams, communicate effectively, document technical information, coordinate schedules, and establish standards and expectations. In addition, my participation in leadership programs such as World Challenge and Know Thy Heritage has further developed my leadership abilities.
                                                </li>
                                                <li>
                                                <b> Production </b><br/>
                                                My diverse project experience, in-depth understanding of the game development pipeline, and leadership and teaching expertise position me as a strong candidate for production-related roles in the gaming industry. I have a clear vision of the steps involved in creating art assets and software structures, and I am adept at setting realistic expectations and working with others to achieve common goals.
                                                </li>
                                                <li>
                                                <b> Game Design </b><br/>
                                                I have a passion for creating magical moments in games and a fascination for the game design process. Throughout my career, I have consistently approached projects from the player perspective, and I have a keen eye for testing and refining game design elements.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>*/}

                                    {/*<TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <b> Gaming </b><br/>
                                                    A chef should love food. My childhood was practically defined by Elder Scrolls, Naruto Storm, Portal, Infamous, Crash Bandicoot and a dozen other titles which have inspired me into adulthood. My favorite games currently are story games such as Undertale and Life is Strange, however I also have a taste for Rhythm games (DDR, Beat Saber), RPGs (Dark Souls, Fallout), Social Games (Overwatch, VRChat, Minecraft), Platformers (Cuphead) and Puzzles (Magrunner, Q.U.B.E). I am currently working my way through Dark Souls, after which will come Before Your Eyes, Sekiro and Bloodborne, followed then by the Uncharted Franchise. My list after that is perhaps unattainably long!
                                                </li>
                                                <li>
                                                    <b> Guitar </b><br/>
                                                    I have been practicing the acoustic guitar on and off for nearly a decade, however I have only recently become near addicted. I will continue to study this soothing and liberating instrument!
                                                </li>
                                                <li>
                                                    <b> World Challenge </b><br/>
                                                    During the (Australian) summer of 2017, I departed on a month long youth leadership program with World Challenge. We traveled the island of Borneo, hiking, volunteering, camping and meeting locals. Practical volunteer work involved a week of helping to build a community center, laying bricks at the foundation. 
                                                </li>
                                                <li>
                                                    <b> Australian Youth Climate Coalition </b><br/>
                                                    Throughout much of 2019 to early 2020, I was involved with volunteer work for the AYCC. This is an organization committed to combating the expansion of fossil fuels within Australia and encouraging the transition to renewable energy. My work primarily involved entering local strategy meetings to discuss tactics and phone banking for recruitment purposes.
                                                </li>
                                                <li>
                                                    <b> Know Thy Heritage </b><br/>
                                                    In the (American) summer of 2019, I volunteered for a Palestinian diaspora organization which sought to raise awareness of the social struggles in the region with the aim of building bridges and fostering peace. We engaged with countless municipal leaders, business-people, educators, activists and politicians in order to gain a broader understanding of the social and political situation and the journey towards potential solutions. This was an enlightening experience I will carry with me as our world continues to face a variety of complex social issues.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>*/}

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <b>VFX Contractor<span> - Alta VR </span></b><br/> 2024 - 2025 <br/> I am currently developing a broad suite of highly optimized VFX for Alta: Project 2. This includes baking houdini simulations to vertex animation textures, coding custom shader effects and using particle systems.
                                               </li>
                                               <li>
                                                   <b>Technical Artist<span> - Ramen VR </span></b><br/> 2023 <br/> At
                                                   Ramen, I worked as a Tech Artist on a VR MMO called "Zenith: The Last
                                                   City". My job included optimizing assets and code for VR performance,
                                                   creating tools for the art pipeline and bug fixing.
                                               </li>
                                               <li>
                                                   <b>Technical Art
                                                       Intern<span> - Alta VR </span></b><br/> 2023 <br/> As a Technical
                                                   Art Intern at Alta VR, I was actively involved in the research and
                                                   development of optimized pipelines, and the creation of efficient
                                                   development tools using Unity and Houdini. I was also privileged to
                                                   work with a highly skilled team of technical artists, who imparted
                                                   their expertise and best practices to me.
                                               </li>
                                               <li>
                                                   <b>Lead Technical Teaching Assistant<span> - Building Virtual Worlds, Carnegie Mellon University </span></b><br/> 2022 <br/> As
                                                   the Lead Technical Teaching Assistant for Carnegie Mellon's Building
                                                   Virtual Worlds class, I was responsible for leading and coordinating
                                                   a team of 11 TAs. I effectively managed equipment and provided
                                                   technical support to a diverse student body of developers while
                                                   ensuring high standards of performance. I honed my skills in rapidly
                                                   learning new platforms and environments in order to provide effective
                                                   teaching.
                                               </li>
                                               <li>
                                                   <b>Art Teaching
                                                       Assistant<span> - NHSGA, Carnegie Mellon University </span></b><br/> Summer
                                                   2022 <br/> As a Teaching Assistant for the NHSGA program, I taught
                                                   classes in 3D for games using Maya to high school students. I
                                                   provided individual support for both art and programming, and
                                                   improved my ability to communicate complex information in a clear and
                                                   concise manner.
                                               </li>
                                               <li>
                                                   <b>Casual IT Specialist/Web Developer<span> - Asia Institute, Arabic Department, University of Melbourne </span></b><br/> 2020
                                                   - 2021 <br/> During my time at the Asia Institute, I helped develop
                                                   web resources for an Arabic textbook. This involved utilizing React
                                                   and Javascript to create engaging and informative resources, as well
                                                   as providing general IT support to the department.
                                               </li>
                                               <li>
                                                   <b>Game Development Intern<span> - Academy of Interactive Entertainment</span></b><br/> 2015 <br/> As
                                                   a Game Development Intern at AIE, I received comprehensive training
                                                   in the fundamentals of Maya and Unity Engine, as well as an in-depth
                                                   overview of the game development pipeline from inception to
                                                   development.
                                               </li>
                                               <li>
                                                   <b>C# Programmer (Student
                                                       Intern)<span> - CommonLook</span></b><br/> 2014 -
                                                   2015 <br/> During my internship at CommonLook, I developed software
                                                   for converting XML data streams to well-formatted PDF documents using
                                                   C# in Visual Studio. This software was integrated into the CommonLook
                                                   platform and is widely used by numerous corporations around the
                                                   world.
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <b>Master of Entertainment Technology (Tornado Award)<span> - Carnegie Mellon University, Entertainment Technology Center</span></b><br/> June 2023
                                                   <br/>Of the 89 students in my program I was honored to receive one of 3 awards. The Tornado award is granted to the student who goes up against the status quo, the existing parameters and limitations of the entertainment technology industry and makes an impact.                                               </li>
                                               <li>
                                                   <b>Bachelor of Science (Computing and Software Systems)<span> - University of Melbourne, Parkville</span></b><br/> 2021
                                               </li>
                                               <li>
                                                   <b>University Extension IT Program Certificate of High Achievement (Dux)<span> - Monash University, Clayton Campus</span></b><br/> 2017
                                                   <br/>I was honored to receive Dux as the top performing student in this program.

                                               </li>
                                               <li>
                                                   <b>Victorian Certificate of Education (ATAR: 98.30)<span> - John Monash Science School</span></b><br/> 2017
                                                   <br/>The Australian Tertiary Admissions Rank is a percentile ranking system for high school graduates in Australia. I was grateful (and surprised!) to find myself in the top 2%
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;