import React from 'react';
import PortfolioPage from '../PortfolioPage';
import ThreeDArtSummary from "../Posts/ThreeDArtSummary";

const ThreeDArtPortfolio = (props) => {
    return (
        <PortfolioPage {...props} title={`3D Art Portfolio`} list={'3D art'}>
            <ThreeDArtSummary/>
        </PortfolioPage>
    )
}

export default ThreeDArtPortfolio;