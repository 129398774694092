import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import Post from "./Templates/post";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import YouTube from 'react-youtube';

import 'react-pro-sidebar/dist/css/styles.css';

class Zenith extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          scrollPos: 0,
          video: '',
          videoStart: 0,
        }
        this.openModal = this.openModal.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {      
        this.setState({
            scrollPos: window.scrollY,
        })
    }

    openModal (videoURL, start = 0) {
        this.setState({isOpen: true, video:videoURL, videoStart:start})
    }
    render(){
        return(
            <React.Fragment>

                <Post shortname='zenith'>

                    {/* For playing videos */}
                    <div className="portfolio-thumb-inner">
                        <div className="thumb position-relative mb--30">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.video}
                                        youtube={{start: this.state.videoStart}}
                                        onClose={() => this.setState({isOpen: false})}/>
                        </div>
                    </div>

                    {/* Start Portfolio Details */}
                    <div className="inner">
                        <p className="subtitle">As a Technical Artist for <a href="https://zenithmmo.com/"><i>Zenith:
                            The Last City</i></a>, I worked in a 20 person team in a fast-paced development environment
                            on a live-service VR MMO. I made optimizations, created tools and helped fixed bugs.</p>

                        <YouTube videoId={'oPEho2Zb_JM'} iframeClassName={'youtubeIframe'}/>
                        
                        <p className="subtitle">My work included:</p>
                        <h3>Optimizations</h3>
                        <p className="subtitle">
                            <ul>
                                <li>Modifying UI and VFX assets to reduce draw calls.</li>
                                <li>Reducing shader complexity.</li>
                                <li>Adjusting LODs for assets.</li>
                                <li>Creating a culling pipeline.</li>
                                <li>Finding unoptimized code and making suggestions for engineers.</li>
                            </ul>
                        </p>
                        <h3>Tools</h3>
                        <p className="subtitle">
                            <ul>
                                <li>Auto LOD-distance setting based on occlusion</li>
                                <li>Auto atlasing</li>
                            </ul>
                        </p>
                        <h3>Bugs</h3>
                        <p className="subtitle">
                            <ul>
                                <li>Mass prefab swapping and transformation fixing</li>
                                <li>Fixing holes in the world</li>
                                <li>Reducing atlasing and lighting artifacts</li>
                                <li>Fixing issues in Navmesh generation</li>
                            </ul>
                        </p>
                    </div>
                    {/* End Portfolio Details */}

                </Post>
            </React.Fragment>
        )
    }
}

export default Zenith;
