import React, { Component } from "react";
import YouTube from 'react-youtube';

class ThreeDArtSummary extends Component{
    constructor () {
        super()
    }

    render(){
        return (
            <div className="col-lg-12">
                <p style={{color: '#FFFFFF'}}>
                    I have experience using blender and substance painter for modelling, sculpting, texturing, rigging and animation. This part of the site is a little out of date.
                </p>
                <br/>
                <br/>
                <br/>
            </div>
        )
    }

}

export default ThreeDArtSummary;