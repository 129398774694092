import React from "react";
import {webMFormattedSimple} from "./resourceFinder";

const IMAGE_TYPE = {
    SIMPLE_WEBM:0,
    THUMBNAIL_IMG:1
}

export const compileImage = (project, opacity = 1) => {
    switch (project.imgType){
        case IMAGE_TYPE.SIMPLE_WEBM:
            return webMFormattedSimple(project.image, {}, project.bkgCol, opacity);
        case IMAGE_TYPE.THUMBNAIL_IMG:
            return <div className={'thumbnail '+project.image}></div>;
        default:
            return <div/>;
    }
}




export const projectList = [
{
    shortname: 'gregot',
    image:'GregotThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#4255b3',
    category: 'Character Animation Project (2022)',
    tools: 'ZBrush | Maya | Substance Painter | Houdini',
    skills: ['3D art', 'technical art'],
    pride: {
        '3D art' : 8.5,
        'technical art' : 8.1
    },
    title: 'Gregot Climb',
    href: '/gregotClimb',
    related: [],
    backgroundGif: {
        id:11,
        settings:{"opacity":"0.2"},
        viewWidth:1267,
    }
},
{
    shortname: 'ecotopia',
    image:'ecotopiaThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#dbb580',
    category: 'Game Project (2022)',
    tools: 'Unity C#',
    skills: ['programming'],
    pride: {
        'programming' : 8.7
    },
    title: 'Project Ecotopia',
    href: '/ecotopia',
    related: [],
    titleImg : 'ecotopiaTitle.png'
},/*
{
    shortname: 'zenith',
    image:'zenithThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#dbb580',
    category: 'Technical Artist (2023)',
    tools: 'Unity | HLSL | Blender | ShaderGraph',
    skills: ['technical art'],
    pride: {
        'technical art' : 9.5
    },
    title: 'Zenith: The Last City',
    href: '/zenith',
    related: [],
    titleImg : 'zenithTitle.png'
},
{
    shortname: 'alta2',
    image:'alta2Thumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#dbb580',
    category: 'VFX Contractor (2024-2025)',
    tools: 'Unity | Houdini | HLSL | Blender | ShaderGraph',
    skills: ['technical art'],
    pride: {
        'technical art' : 9.5
    },
    title: 'Alta: Project 2',
    href: '/alta2',
    related: [],
    titleImg : 'altaTitle.png'
},
{
    shortname: 'perilaxity',
    image:'perilaxityThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#dbb580',
    category: 'WIP Project (2024-2025)',
    tools: 'Blender | ShaderGraph',
    skills: ['3D art', 'technical art'],
    pride: {
        '3D art' : 8.5,
        'technical art' : 7.5,
    },
    title: 'Perilaxity',
    href: '/perilaxity',
    related: [],
    titleImg : 'perilaxityTitle.png'
},*/
{
    shortname: 'innerMatter',
    image:'InnerMatterThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#527b85',
    category: 'VR Meditation Project (2022)',
    tools: 'Unity C# | ShaderGraph | Blender | Substance Painter | Houdini',
    skills: ['3D art', 'programming', 'technical art'],
    pride: {
        '3D art' : 9.5,
        'programming' : 8.5,
        'technical art' : 7.5
    },
    title: 'Inner Matter',
    href: '/innerMatter',
    related: ['toTheEdge', 'intertwined', 'dispEst'],
    backgroundGif: {
        id:18,
        settings:{"opacity":"0.4"},
        viewWidth:1920,
    }
},
{
    shortname: 'keysOfOb',
    image:'KeysThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#090012',
    category: 'School Game Project (2021)',
    tools: 'Unity C# | ShaderGraph | Midi Keyboard',
    skills: ['programming', 'technical art'],
    pride: {
        'programming' : 6,
        'technical art' : 7
    },
    title: 'The Keys of Obliteration',
    href: '/keysOfObliteration',
    related: ['gregot', 'spaceRPS', 'iamProj'],
    backgroundGif: {
        id:16,
        settings:{"opacity":"0.2"},
        viewWidth:1920,
    }
},
{
    shortname: 'toTheEdge',
    image:'EdgeThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#111414',
    category: 'School Game Project (2021)',
    tools: 'Unity C# | CAVE System',
    skills: ['programming'],
    pride: {
        'programming' : 7
    },
    title: 'To the Edge of the World',
    href: '/toTheEdge',
    related: ['dispEst', 'keysOfOb', 'spaceRPS'],
    backgroundGif: {
        id:15,
        settings:{"opacity":"0.2"},
        viewWidth:1920,
    }
},
{
    shortname: 'spaceRPS',
    image:'SpaceRPSThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#3371a1',
    category: 'School Game Project (2021)',
    tools: 'Unity C# | ShaderGraph | VR',
    skills: ['programming', 'technical art'],
    pride: {
        'programming' : 7,
        'technical art' : 6.5
    },
    title: 'Space RPS',
    href: '/spaceRPS',
    related: ['keysOfOb', 'innerMatter', 'intertwined'],
    backgroundGif: {
        id:14,
        settings:{"opacity":"0.2"},
        viewWidth:1920,
    }
},
{
    shortname: 'drSpoof',
    image:'SpoofThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#8c9c92',
    category: 'School Game Project (2021)',
    tools: 'Unity C# | ShaderGraph | VR',
    skills: ['programming', 'technical art'],
    pride: {
        'programming' : 7,
        'technical art' : 6
    },
    title: 'Dr. Spoofinsmirtz',
    href: '/drSpoof',
    related: ['hexit', 'spaceRPS', 'iamProj'],
    backgroundGif: {
        id:13,
        settings:{"opacity":"0.2"},
        viewWidth:1920,
    }
},
{
    shortname: 'makingConnections',
    image:'ConnectionsThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#a2a832',
    category: 'School Game Project (2021)',
    tools: 'Unity C# | ShaderGraph | Hololens 2',
    skills: ['programming'],
    pride: {
        'programming' : 7,
    },
    title: 'Making Connections',
    href: '/makingConnections',
    related: ['hexit', 'drSpoof', 'iamProj'],
    backgroundGif: {
        id:12,
        settings:{"opacity":"0.2"},
        viewWidth:1920,
    }
},
{
    shortname: 'nightOfKingSam',
    image:'KingSamThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#262320',
    category: 'School Game Project (2021)',
    tools: 'Unity C#',
    skills: ['programming', 'game design'],
    pride: {
        'programming' : 6,
        'game design' : 3
    },
    title: 'Night of King Sam\'s Zombie Clone Invasion',
    href: '/nightOfKingSam',
    related: ['hexit', 'sop', 'drSpoof'],
    backgroundGif: {
        id:17,
        settings:{"opacity":"0.2"},
        viewWidth:1920,
    }
},
{
    shortname: 'intertwined',
    image:'IntertwinedThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#452e63',
    category: 'School Art Project (2021)',
    tools: 'HLSL | ShaderGraph',
    skills: ['technical art'],
    pride: {
        'technical art' : 8
    },
    title: 'Intertwined',
    href: '/intertwined',
    related: ['hexit', 'sop', 'iamProj'],
    backgroundGif: {
        id:9,
        settings:{"opacity":"0.2"},
        viewWidth:1280,
    }
},
{
    shortname: 'iamProj',
    image: 'IAMThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#1b273b',
    category: 'School Art Projects (2021)',
    tools: 'ShaderGraph | Blender',
    skills: ['technical art'],
    pride: {
        'technical art' : 8
    },
    title: 'Interactive Art Media',
    href: '/IAMProjects',
    related: ['sop', 'magicGame', 'intertwined'],
    backgroundGif: {
        id:8,
        settings:{"opacity":"0.8"},
        viewWidth:1280,
    }
    
},
{
    shortname: 'sop',
    image: 'METSOPThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: 'black',
    category: 'Game Project (2020)',
    tools: 'Unity C# | ShaderGraph | Substance Painter',
    skills: ['programming', 'technical art', '3D art'],
    pride: {
        'programming' : 6,
        'technical art' : 3,
        '3D art' : 2
    },
    title: 'MET Statement of Purpose',
    href: '/sopGame',
    related: ['hexit', 'intertwined', 'magicGame'],
    backgroundGif: {
        id:7,
        settings:{"opacity":"0.5"},
        viewWidth:1280,
    }
},
{
    shortname: 'magicGame',
    image: 'magicGame',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#1c0233',
    category: 'Game Project (2020)',
    tools: 'ShaderGraph | 3DS Max | Substance Painter | VR',
    skills: ['3D art', 'technical art'],
    pride: {
        '3D art' : 8,
        'technical art' : 5
    },
    title: 'Magic Game',
    href: '/magicGame',
    related: ['intertwined', 'iamProj', 'hexit'],
    backgroundGif: {
        id:6,
        settings:{"opacity":"0.3"},
        viewWidth:1552,
    }
},
{
    shortname: 'hexit',
    image: 'hexitThumb',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: 'black',
    category: 'Game Project (2019)',
    tools: 'Unity C# | HLSL | Blender',
    skills: ['programming', 'technical art'],
    pride: {
        'programming' : 4,
        'technical art' : 6
    },
    title: 'Hexit',
    href: '/hexit',
    related: ['intertwined', 'magicGame', 'sop'],
    backgroundGif: {
        id:4,
        settings:{"opacity":"0.3"}
    }
},
{
    shortname: 'dispEst',
    image: 'dispEst',
    imgType : IMAGE_TYPE.THUMBNAIL_IMG,
    category: 'Research Project (2020)',
    tools: 'Python 2.7 | Unity C#',
    skills: ['programming', 'research'],
    pride: {
        'programming' : 5,
        'research' : 5
    },
    title: 'Using Eye Trackers for Multi-Display Position Estimation',
    href: '/dispEst',
    related: [],
    backgroundGif: {
        id:5,
        settings:{"opacity":"0.3"},
        viewWidth:984,
    }
}

/*,
{
    shortname: 'turnside',
    image: 'tsCave2',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#282f3b',
    category: 'Game Project (2017)',
    title: 'Turnside',
    href: '/turnside',
    related: ['intertwined', 'magicGame', 'sop'],
    backgroundGif: {
        id:2,
        settings:{"opacity":"0.3"},
        viewWidth:1650,
    }
},
{
    shortname: 'misc',
    image: 'wizardTower',
    imgType : IMAGE_TYPE.SIMPLE_WEBM,
    bkgCol: '#3d3d3d',
    category: '(2017-2019)',
    title: 'Misc Projects',
    href: '/miscProjects',
    related: ['iamProj', 'magicGame', 'intertwined'], 
    backgroundGif: {
        id:3,
        settings:{"opacity":"0.3"},
        viewWidth:1644,
    }
},
{
    shortname: 'thisSite',
    image: 'thisSite',
    imgType : IMAGE_TYPE.THUMBNAIL_IMG,
    category: 'Web Development (2020)',
    title: 'This Website',
    href: '/thisSite',
    related: [],
    backgroundGif: {
        id:1,
        settings:{"opacity":"0.3"},
        viewWidth:1920,
    }
}*/
]

export const projectListOf = (projectType, list = projectList) => {
    let projects = [];

    // Find all matching projects
    for (let i = 0; i < list.length; i++){
        if ('skills' in list[i]){
            if (list[i].skills.includes(projectType)){
                projects.push(list[i]);
            }
        }
    }

    // Order by pride
    projects.sort((a, b) => {
        return (b.pride[projectType] - a.pride[projectType]);
    });

    return projects;
}


export const findProject = shortname => {
    for (let i = 0; i < projectList.length; i++){
        if (projectList[i].shortname == shortname)
            return projectList[i];
    }
    return null;
}